import { BuyOrder } from "./BuyOrder";
import { ROGOrderItem } from "./ROGOrderItem";

export class ROGOrder extends BuyOrder
{
    po;

    get POId() {
        return !!this.po ? this.po.id : null
    }

    get isPOChangeable() {
        return !this.created_at
    }

    get unmatchList() {
        return this.orderItems.filter(item => item.expectedQuantity != item.quantity).map(item => item.name + " ( ต้องการ: " + item.expectedQuantity + ", รับจริง: " + item.quantity + " )")
    }

    constructor(parameters) {
        const { po } = parameters

        super(parameters);

        this.po = po
    }

    addOrderItem(rogOrderItem) {
        if (!(rogOrderItem instanceof ROGOrderItem)) throw "can add only ROGOrderItem Class"
        
        this.orderItems.push(rogOrderItem)
    }

    serialize() {
        return {
            items: this.orderItems.map(data => {
                return {
                  id: data.id,
                  quantity: data.quantity,
                  discount: data.discount,
                  discount_type: data.discount_type,
                  note: data.note,
                  price: data.price,
                }
            }),            
            taxno: this.taxno,
            address: this.address,
            note: this.note,                        
            delivery_cost: this.delivery_cost,
            discount: this.discount,
            discount_type: this.discount_type,            
            warehouse_id: this.warehouseId,
            supplier_id: this.supplierId,
            created_at: this.created_at,
            misc_cost: this.misc_cost,
            po_id: this.POId
        }
    }
}