import { OrderItem } from "./OrderItem";

export class ROGOrderItem extends OrderItem
{    
    #expectedQuantity = 0;

    get expectedQuantity() {
        return this.#expectedQuantity
    }

    constructor({ id, images, name, sku, barCode, quantity, expectedQuantity, vat, price, discount, discount_type, note, unit }) {                
        super(
            id,
            images && images.length > 0 ? images[0].URI : null,
            name,
            sku,
            barCode,
            note,
            quantity,
            price,
            !!discount ? discount : 0,
            !!discount_type ? discount_type : 'percent',
            !!vat ? vat : 0,
            unit
        );    

        if (!!expectedQuantity)
            this.#expectedQuantity = expectedQuantity
    }
}